import { createStyles, Theme } from '@material-ui/core/styles';

import { commonStyles } from '../Select.styles';

export function styles(theme: Theme) {
  return createStyles({
    ...commonStyles(theme),

    valueMultiple: {},
  });
}
