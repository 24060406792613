import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ spacing }: Theme) => {
  return createStyles({
    root: {},
    alignRight: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',

      '& $paginationActions': {
        marginLeft: 0,
        marginRight: spacing(4),
      },
    },
    paginationInfo: {},
    paginationInfoCounts: {
      fontSize: 14,
      fontWeight: 500,
      whiteSpace: 'nowrap',
      userSelect: 'none',
    },
    paginationOf: {
      color: colors.grey.darkish,
      padding: '0 4px',
    },
    paginationInfoDivider: {
      color: colors.grey.main,
    },
    paginationActions: {
      marginLeft: spacing(4),
    },
    button: {
      minWidth: 32,
      height: 32,

      '&:hover': {
        '& $buttonLabel': {
          color: colors.black.darkest,
        },
      },

      '&:last-of-type': {
        marginLeft: spacing(2),
      },
    },
    buttonLabel: {
      fontSize: 30,
      color: colors.grey.lightish,
      marginBottom: 3,
    },
    pageDisabled: {
      backgroundColor: '#F2F4F7', //TODO grey neutral 300 in future
    },
  });
};
