import {
  FormControl,
  FormLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  TooltipProps,
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@material-ui/icons';
import cx from 'classnames';
import { ReactNode, useMemo } from 'react';

import { Ellipsis } from '@shared/components/ellipsis';
import { FieldHelperText } from '@shared/components/field-helper-text';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './MultiSelect.styles';
import { MultiSelectOnChange, Option, Value } from '../Select.types';

type Classes = WithStyles<typeof styles>;

export interface MultiSelectProps extends Omit<MuiSelectProps, 'classes' | 'onChange' | 'label'>, Classes {
  label?: React.ReactNode;
  border?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  infoText?: string;
  options: Array<Option>;
  tooltip?: string;
  tooltipPlacement?: TooltipProps['placement'];
  value?: Array<Value>;
  onChange?: MultiSelectOnChange;
  renderCustomOption?: (option: Option) => React.ReactNode;
}

const MultiSelectComponent: React.FC<MultiSelectProps> = ({
  border = true,
  label,
  classes,
  errorText,
  fullWidth = false,
  infoText = '',
  options,
  placeholder,
  renderValue,
  tooltip,
  tooltipPlacement = 'left',
  value,
  onChange,
  renderCustomOption,
  ...otherProps
}) => {
  const placeholderVisible = useMemo(() => !value || (Array.isArray(value) && value.length === 0), [value]);

  const handleChange = (e: React.ChangeEvent<any>) => {
    e.persist();

    const { value } = e.target;

    if (onChange && value) {
      const option = value.map((id: string) => getOption(id));

      onChange(e, value, option);
    }

    // When user choose empty option ("Select option")
    if (onChange && !value) {
      onChange(e, []);
    }
  };

  const handleClose = (e: React.ChangeEvent<any>) => {
    if (otherProps?.onClose) otherProps.onClose(e);
    setTimeout(() => {
      // @ts-ignore
      document?.activeElement?.blur();
    }, 0);
  };

  const getOption = (id: Id) => {
    return options.find((option) => option.id == id) as Option;
  };

  const renderSelectorValue = (v: Array<Value>) => {
    if (renderValue && v) {
      return renderValue(v as Array<Value>);
    }

    const label = v.map((id: Id) => getOption(id)?.label).join(', ');

    if (placeholderVisible) {
      return (
        <Ellipsis
          withTooltip={false}
          maxWidth="100%"
          text={placeholder}
          classes={{
            root: cx(classes.placeholder, { [classes.placeholderDisabled]: otherProps.disabled }),
          }}
        />
      );
    }

    return (
      <div
        className={cx(classes.value, classes.valueMultiple, { [classes.placeholderDisabled]: otherProps.disabled })}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    );
  };

  const renderOption = (option: Option) => {
    if (renderCustomOption) {
      return renderCustomOption(option);
    }

    if (option.disabled) {
      return (
        <Tooltip data-value={option.id} placement="left" key={option.id} title={option.tooltip as string}>
          <MenuItem
            value={option.id}
            classes={{
              root: cx(classes.menuItem, classes.menuItemDisabled),
              selected: classes.menuItemSelected,
            }}
          >
            <span dangerouslySetInnerHTML={{ __html: option.label }} />
          </MenuItem>
        </Tooltip>
      );
    }

    return (
      <MenuItem
        value={option.id}
        key={option.id}
        classes={{
          root: classes.menuItem,
          selected: classes.menuItemSelected,
        }}
      >
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
      </MenuItem>
    );
  };

  let content = (
    <>
      {label && (
        <FormLabel classes={{ root: classes.labelRoot }} component="legend" error={otherProps.error}>
          {label}
        </FormLabel>
      )}
      <MuiSelect
        {...otherProps}
        displayEmpty
        multiple
        defaultValue={placeholder}
        value={value}
        onChange={handleChange}
        className={cx(classes.select, { [classes.selectDisabled]: otherProps.disabled })}
        classes={{
          root: classes.muiSelectRoot,
          select: classes.muiSelect,
          icon: cx(classes.muiIcon, { [classes.iconPlaceholder]: placeholderVisible }),
          outlined: classes.muiOutlined,
        }}
        error={otherProps.error}
        placeholder={placeholder}
        renderValue={renderSelectorValue as (value: unknown) => ReactNode}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onClose={handleClose}
      >
        {options.map((option) => renderOption(option))}
      </MuiSelect>
      <FieldHelperText
        classes={{ root: classes.helperText }}
        error={!!otherProps.error}
        errorText={errorText}
        infoText={infoText}
      />
    </>
  );
  if (tooltip) {
    content = (
      <Tooltip placement={tooltipPlacement} title={tooltip}>
        {content}
      </Tooltip>
    );
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      classes={{ root: cx(classes.root, { [classes.rootNoBorder]: !border, [classes.rootFullWidth]: fullWidth }) }}
      error={otherProps.error}
    >
      {content}
    </FormControl>
  );
};

export const MultiSelect = withStyles(styles)(MultiSelectComponent);
