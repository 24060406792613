import { createStyles } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = () =>
  createStyles({
    root: {},
    search: {},
    searchBtn: {
      minWidth: 42,
    },
    searchIcon: {
      color: colors.grey.main,
      marginBottom: 2,
    },
    searchFieldInput: {
      minWidth: 200,
      backgroundColor: 'transparent',
    },
    clearIcon: {
      cursor: 'pointer',
      color: colors.grey.main,
      fontSize: 18,

      '&:hover': {
        color: colors.red.darkish,
      },
    },
  });
