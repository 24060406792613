import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ shape }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    label: {
      color: colors.grey.darker,
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 10,
      lineHeight: 'normal',
    },
    select: {
      width: '100%',
      height: 36,
      borderRadius: shape.borderRadius,
    },
  });
