import { createStyles, Theme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/styles';

import { colors } from '@shared/theme/colours';
import { ButtonColour, ButtonSize } from '@shared/types/common/button';

// TODO: Change all hexcodes when palette is updated

type ButtonStyling = Record<
  ButtonColour,
  {
    default: CSSProperties;
    hovered: CSSProperties;
    active: CSSProperties;
    disabled: CSSProperties;
  }
>;

const buttonDisabledStyling: CSSProperties = {
  backgroundColor: colors.kato.grey[200],
  borderColor: colors.kato.grey[200],
  boxShadow: 'none',
  color: colors.kato.grey[400],
  cursor: 'not-allowed',
};

export const getCoreButtonStyling = (theme: Theme): ButtonStyling => ({
  // Primary Special
  [ButtonColour.primarySpecial]: {
    default: {
      backgroundColor: theme.palette.primarySpecial.main,
      borderColor: theme.palette.primarySpecial.main,
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: theme.palette.primarySpecial.dark,
      borderColor: theme.palette.primarySpecial.dark,
    },
    active: {
      backgroundColor: theme.palette.primarySpecial.dark,
      borderColor: theme.palette.primarySpecial.dark,
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Primary
  [ButtonColour.primary]: {
    default: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    active: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary
  [ButtonColour.secondary]: {
    default: {
      backgroundColor: colors.white.lightest,
      borderColor: '#D1D6DF',
      color: '#344154',
    },
    hovered: {
      backgroundColor: '#F7F8FA',
      borderColor: '#D1D6DF',
    },
    active: {
      backgroundColor: '#EAECF0',
      borderColor: '#D1D6DF',
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary Colour
  [ButtonColour.secondaryColour]: {
    default: {
      backgroundColor: colors.white.lightest,
      borderColor: colors.kato.grey[300],
      color: colors.kato.navy[950],
      boxShadow: 'none',
    },
    hovered: {
      backgroundColor: colors.kato.grey[100],
      borderColor: colors.kato.navy[950],
      boxShadow: 'none',
      color: colors.kato.navy[950],
    },
    active: {
      backgroundColor: colors.kato.grey[100],
      borderColor: colors.kato.navy[950],
      boxShadow: 'none',
      color: colors.kato.navy[950],
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Secondary Ghost
  [ButtonColour.secondaryGhost]: {
    default: {
      backgroundColor: colors.white.lightest,
      border: 'none',
      boxShadow: 'none',
      color: '#344154',
    },
    hovered: {
      backgroundColor: '#F2F4F7',
    },
    active: {
      backgroundColor: '#EAECF0',
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Error
  [ButtonColour.error]: {
    default: {
      backgroundColor: '#D92D20',
      borderColor: '#D92D20',
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: '#B42318',
      borderColor: '#B42318',
    },
    active: {
      backgroundColor: '#912018',
      borderColor: '#912018',
    },
    disabled: { ...buttonDisabledStyling },
  },
  // Teal
  [ButtonColour.teal]: {
    default: {
      backgroundColor: colors.kato.teal[500],
      borderColor: colors.kato.teal[500],
      color: colors.white.lightest,
    },
    hovered: {
      backgroundColor: colors.kato.teal[600],
      borderColor: colors.kato.teal[600],
    },
    active: {
      backgroundColor: colors.kato.teal[600],
      borderColor: colors.kato.teal[600],
    },
    disabled: { ...buttonDisabledStyling },
  },
});

const currentColorStyling = {
  '& $buttonLabel i': { color: 'currentColor' },
  '& $buttonLabel svg': { color: 'currentColor' },
  '& $buttonLabel svg path': { stroke: 'currentColor' },
};

export const styles = (theme: Theme) => {
  const coreButtonStyling = getCoreButtonStyling(theme);

  return createStyles({
    root: {
      border: '1px solid transparent',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      minWidth: 10,
      ...currentColorStyling,
    },
    buttonActive: {},
    buttonDisabled: {
      cursor: 'not-allowed',
      pointerEvents: 'none',
    },
    buttonHovered: {},

    buttonStartIcon: {
      marginLeft: 0,
      marginRight: theme.spacing(2),
    },
    buttonLabel: {
      fontWeight: 500,
    },
    buttonEndIcon: {
      marginLeft: theme.spacing(2),
      marginRight: 0,
    },

    // Colour - Primary Special
    [ButtonColour.primarySpecial]: {
      ...coreButtonStyling[ButtonColour.primarySpecial].default,
      ...currentColorStyling,

      '&:hover': {
        ...coreButtonStyling[ButtonColour.primarySpecial].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.primarySpecial].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.primarySpecial].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.primarySpecial].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.primarySpecial].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Primary
    [ButtonColour.primary]: {
      ...coreButtonStyling[ButtonColour.primary].default,
      ...currentColorStyling,

      '&:hover': {
        ...coreButtonStyling[ButtonColour.primary].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.primary].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.primary].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.primary].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.primary].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Secondary
    [ButtonColour.secondary]: {
      ...coreButtonStyling[ButtonColour.secondary].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },

      '&:hover': {
        ...coreButtonStyling[ButtonColour.secondary].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.secondary].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.secondary].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.secondary].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.secondary].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Secondary Colour
    [ButtonColour.secondaryColour]: {
      ...coreButtonStyling[ButtonColour.secondaryColour].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },

      '&:hover': {
        ...coreButtonStyling[ButtonColour.secondaryColour].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.secondaryColour].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.secondaryColour].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.secondaryColour].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.secondaryColour].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Secondary Ghost
    [ButtonColour.secondaryGhost]: {
      ...coreButtonStyling[ButtonColour.secondaryGhost].default,
      '& $buttonLabel i': { color: '#7E8899' },
      '& $buttonLabel svg': { color: '#7E8899' },
      '& $buttonLabel svg path': { stroke: '#7E8899' },

      '&:hover': {
        ...coreButtonStyling[ButtonColour.secondaryGhost].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.secondaryGhost].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.secondaryGhost].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.secondaryGhost].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.secondaryGhost].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Error
    [ButtonColour.error]: {
      ...coreButtonStyling[ButtonColour.error].default,
      ...currentColorStyling,

      '&:hover': {
        ...coreButtonStyling[ButtonColour.error].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.error].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.error].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.error].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.error].disabled,
        ...currentColorStyling,
      },
    },
    // Colour - Teal
    [ButtonColour.teal]: {
      ...coreButtonStyling[ButtonColour.teal].default,
      ...currentColorStyling,

      '&:hover': {
        ...coreButtonStyling[ButtonColour.teal].hovered,
        ...currentColorStyling,
      },
      '&$buttonHovered': {
        ...coreButtonStyling[ButtonColour.teal].hovered,
        ...currentColorStyling,
      },
      '&:active': {
        ...coreButtonStyling[ButtonColour.teal].active,
        ...currentColorStyling,
      },
      '&$buttonActive': {
        ...coreButtonStyling[ButtonColour.teal].active,
        ...currentColorStyling,
      },
      '&$buttonDisabled': {
        ...coreButtonStyling[ButtonColour.teal].disabled,
        ...currentColorStyling,
      },
    },

    // Sizes
    [ButtonSize.extraSmall]: {
      height: 24,
      padding: theme.spacing(0, 1.5),

      '& $buttonLabel': {
        fontSize: 12,
      },
    },
    [ButtonSize.small]: {
      height: 32,
      padding: theme.spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.mediumLegacy]: {
      height: 36,
      padding: theme.spacing(0, 3),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.medium]: {
      height: 40,
      padding: theme.spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 14,
      },
    },
    [ButtonSize.large]: {
      height: 48,
      padding: theme.spacing(0, 4),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    // Icon sizes
    [ButtonSize.iconSmall]: {
      height: 32,
      padding: theme.spacing(3),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
    [ButtonSize.iconLarge]: {
      height: 40,
      padding: theme.spacing(2),

      '& $buttonLabel': {
        fontSize: 16,
      },
    },
  });
};
