import { createStyles, Theme } from '@material-ui/core/styles';

import {
  layoutHorizontalSpacing,
  layoutHorizontalTabletSpacing,
  layoutVerticalSpacing,
  layoutVerticalTabletSpacing,
} from '@shared/constants/layout/paddings';
import { colors } from '@shared/theme/colours';

export const pageHeaderHeight = 60;
export const pageNavigationHeight = 49;
export const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      zIndex: 3,
    },

    header: {
      minHeight: pageHeaderHeight,
      backgroundColor: colors.white.lightest,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: spacing(layoutVerticalSpacing, layoutHorizontalSpacing, 0),

      [breakpoints.down('xs')]: {
        padding: spacing(layoutVerticalTabletSpacing, layoutHorizontalTabletSpacing),
        marginBottom: spacing(3),
      },
    },
    headerNoNavigation: {
      paddingBottom: spacing(layoutVerticalSpacing),
    },
    headerWithActions: {
      justifyContent: 'space-between',
    },
    headerTitle: {
      color: colors.black.lightest,
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: '36px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    navigationContainer: {
      backgroundColor: colors.white.lightest,
      boxShadow: '0 1.5px 2.5px 0 rgb(0 18 52 / 10%)',
      padding: spacing(2, layoutHorizontalSpacing, 0),
      zIndex: 3,

      [breakpoints.down('xs')]: {
        padding: spacing(0, layoutHorizontalTabletSpacing),
      },
    },
  });
