import { alpha, createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

export const styles = ({ shape }: Theme) =>
  createStyles({
    root: {},
    button: {
      borderRadius: 0,
    },
    firstButton: {
      borderTopLeftRadius: shape.borderRadius,
      borderBottomLeftRadius: shape.borderRadius,
    },
    lastButton: {
      borderTopRightRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
    activeButton: {
      backgroundColor: alpha(colors.blue.dark, 0.15),
    },
  });
