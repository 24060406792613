import { createStyles, Theme } from '@material-ui/core/styles';

import { colors } from '@shared/theme/colours';

const tabActiveLabelColor = colors.black.lightest;

export const styles = ({ breakpoints, palette, spacing }: Theme) =>
  createStyles({
    root: {
      backgroundColor: colors.white.lightest,
      position: 'relative',
    },

    tabs: {},
    tabsHide: {
      [breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    tab: {
      color: colors.grey.main,
      cursor: 'pointer',
      display: 'inline-flex',
      fontSize: 14,
      fontWeight: 500,
      padding: 0,
      position: 'relative',

      '&:not(:last-child)': {
        marginRight: spacing(5),
      },

      '&:hover': {
        '& $tabLabel': {
          color: tabActiveLabelColor,
        },
      },
    },
    tabUnderline: {
      borderBottom: '2px solid transparent',
      bottom: 0,
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
    },
    tabUnderlineActive: {
      borderBottomColor: palette.primarySpecial.main,
    },
    tabLabel: {
      margin: spacing(2.5, 0),
      maxWidth: 300,
      minWidth: 15,
      overflow: 'hidden',
      position: 'relative',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      userSelect: 'none',
      whiteSpace: 'nowrap',
    },
    tabLabelActive: {
      color: tabActiveLabelColor,
    },

    pageSelector: {
      display: 'none',
      width: '100%',

      [breakpoints.down('xs')]: {
        display: 'inherit',
      },
    },
  });
