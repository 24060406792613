import { createStyles, Theme } from '@material-ui/core/styles';

import { DropDownPosition } from '@shared/components/dropdown/Dropdown';
import { colors } from '@shared/theme/colours';

export const styles = ({ spacing, zIndex }: Theme) => {
  return createStyles({
    wrapper: {
      position: 'relative',
      display: 'inline-block',
    },
    root: {
      padding: spacing(1, 2, 1, 3),
    },
    divider: {
      borderTop: `1px solid ${colors.grey.lightest}`,
      width: '100%',
    },
    dropdownOpen: {
      '& $expandIcon': {
        transform: 'rotate(180deg)',
      },
    },
    buttonLabel: {
      paddingRight: 24,
    },
    buttonLabelNoIcon: {
      paddingRight: 4,
    },
    startIcon: {},
    expandIcon: {
      display: 'inline-block',
      position: 'absolute',
      right: 4,
      top: 'calc(50% - 11px)',
    },
    disabled: {
      pointerEvents: 'none',
    },
    menu: {
      display: 'none',
      position: 'absolute',
      top: 40,
      right: 0,
      zIndex: zIndex.tooltip - 1,
      backgroundColor: colors.white.lightest,
      minWidth: 160,
      boxShadow: '0 7px 40px 3px rgb(0 0 0 / 15%)',
      borderRadius: 6,
      padding: 4,
    },
    menuLeft: {
      left: 0,
      right: 'auto !important',
    },
    menuOpen: {
      display: 'block',
    },
    menuTop: {
      bottom: 40,
      top: 'auto',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 15px',

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.grey.lighter,
      },
    },
    itemDisabled: {
      '&:hover': {
        cursor: 'default',
      },
    },
    itemText: {
      fontSize: 14,
    },
    itemTextMargin: {
      marginLeft: 10,
    },
    [DropDownPosition.BottomRight]: {},
    [DropDownPosition.BottomLeft]: {},
    [DropDownPosition.TopRight]: {},
    [DropDownPosition.TopLeft]: {},
  });
};
